<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <!-- 第二行 -->
        <Menu name="person-helmet" style="background: black;z-index: 999;position: relative;" @change="onProInfoChange($event)"/>
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else id="content" style="    margin-top: -70px;    overflow: hidden;    z-index: 0;    position: inherit;">
            <iframe id="helmetBox" scrolling=false @load="loadFrame" width="100%" height="100%" :src="url" allow="microphone;camera;midi;encrypted-media;" frameborder="0"></iframe>          
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { message } from "ant-design-vue";
import ProInfoCache from "@/utils/cache/ProInfoCache"

moment.locale("zh-cn");
import moment from "moment";
export default {
  data() {
    return {
      loading: true,
      pro:{},
      url:''
    };
  },
  components: {
    Menu,
  },
  created: function () {
    this.loading=false
  },
  mounted() {
      this.pro = ProInfoCache.getProInfo()
      this.load();
  },
  watch: {
  },
  methods: {
      onProInfoChange(e){
        this.pro = e;
      },
      load(){
        this.loading = true;
        this.$http
            .post("/DeviceManage/DeviceSafetyhelmet/GetDisplayUrl", {
                ProId: this.pro.Id,
            })
            .then((resJson) => {
                this.loading = false;
                if(resJson.Success)
                this.url = resJson.Data
                else
                    message.warning(resJson.Msg);

            });
      },
      loadFrame(){
        const oIframe = document.getElementById('helmetBox');
        //const deviceWidth = document.documentElement.clientWidth;
        const deviceHeight = document.documentElement.clientHeight;
        //oIframe.style.width = (Number(deviceWidth)) + 'px'; //数字是页面布局宽度差值
        oIframe.style.height = (Number(deviceHeight)) + 'px'; //数字是页面布局高度差
      }

  },
};
</script>
<style scoped>
.container-title{
    display: none !important;
}
</style>